function voting() {
  let votingItems = document.querySelectorAll('.voting__item');
  let votingFormSubmit = document.querySelector('.voting__form-submit');
  let votingFormDescription = document.querySelector('.voting__form-description');

  if (votingItems.length) {
    votingItems.forEach(item => {
      let input = item.querySelector('input')

      input.addEventListener('input', () => {
        let votingItemActiveCheckboxes = document.querySelectorAll('.voting__item-checkbox:checked');
        let counter = votingItemActiveCheckboxes.length;

        let votingItemNotActiveCheckboxes = document.querySelectorAll('.voting__item-checkbox:not(:checked)');

        console.log(votingItemNotActiveCheckboxes)

        if (counter === 5) {
          votingFormSubmit.disabled = false;
          votingItemNotActiveCheckboxes.forEach(checbox => {
            checbox.disabled = true;
          })
          votingFormDescription.innerHTML = 
          `
          Выбраны 5 мультфильмов.<br/>
          Если ты передумал, нажми на розовую  кнопку под мультиком для отмены и выбери другой
          `
        } else {
          votingFormSubmit.disabled = true;
          votingItemNotActiveCheckboxes.forEach(checbox => {
            checbox.disabled = false;
          })
          if (counter !== 0) {
            votingFormDescription.innerHTML = 
            `
            Осталось еще ${5 - counter}
            `
          } else {
            votingFormDescription.innerHTML = 
            `
            Выбери 5 мультиков и сможешь проголосовать за них
            `
          }
        }
        console.log(counter)
      })
    })
  }
}


export { voting }
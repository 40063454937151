function testInit() {
  let test = document.querySelector('.test');
  
  if (test) {
    console.log('testInit')
    let testBtn = document.querySelector('.test__start');
    testBtn.addEventListener('click', () => {
      test.classList.add('is-active');
    })
  }
}

export { testInit }
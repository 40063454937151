function showsInit() {
  let showsSlider = document.querySelector('.shows__slider');

  if (showsSlider) {
    const swiper = new Swiper(showsSlider, {
      slidesPerView: 7,
      spaceBetween: 40,

      breakpoints: {
        // when window width is >= 0px
        0: {
          slidesPerView: 2,
          spaceBetween: 28
        },
        // when window width is >= 0px
        768: {
          slidesPerView: 4,
          spaceBetween: 28
        },
        // when window width is >= 960px
        960: {
          slidesPerView: 7,
          spaceBetween: 40
        }
      },

      // Navigation arrows
      navigation: {
        nextEl: '.shows__slider .swiper-navigation__btn--next',
        prevEl: '.shows__slider .swiper-navigation__btn--prev',
      },
    });
  }
  
}

export { showsInit }
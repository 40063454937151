function entertainment() {
  let entertainmentAdviceBtn = document.querySelector('.entertainment__advice-btn');
  let entertainmentAdvice = document.querySelector('.entertainment__advice');
  let entertainmentAdviceActiveBlock = document.querySelectorAll('.entertainment__advice-active-block');
  let counter = 0;

  if (entertainmentAdviceBtn) {
    entertainmentAdviceBtn.addEventListener('click', () => {
      entertainmentAdviceBtn.textContent = "Ещё напутствие"
      
      entertainmentAdvice.classList.add('is-active');
      
      entertainmentAdviceActiveBlock.forEach((block, i) => {
        if (i === counter) {
          block.classList.add('is-active')
        } else {
          block.classList.remove('is-active')
        }
      })

      if (counter < entertainmentAdviceActiveBlock.length - 1) counter++
    })
  }
}

export { entertainment }
function garfieldTickerInit() {
  let garfieldTickerSwiper = document.querySelector('.garfield-ticker__swiper');

  if (garfieldTickerSwiper) {
    const swiper = new Swiper(garfieldTickerSwiper, {
      slidesPerView: 'auto',
      spaceBetween: 18,
      speed: 5000,
      loop: true,
      //allowTouchMove: false, // можно ещё отключить свайп
      autoplay: {
        delay: 0,
        disableOnInteraction: false // или сделать так, чтобы восстанавливался autoplay после взаимодействия
      }
    });
  }
}

export { garfieldTickerInit }
function showInit() {
  let showSlider = document.querySelector('.show__slider');

  if (showSlider) {
    const swiper = new Swiper(showSlider, {
      pagination: {
        el: '.show__slider .swiper-pagination',
      },
    
      // Navigation arrows
      navigation: {
        nextEl: '.show__slider .swiper-navigation__btn--next',
        prevEl: '.show__slider .swiper-navigation__btn--prev',
      },
    });
  }
}

export { showInit }
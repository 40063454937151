function contestGalleryInit() {
  let contestGallerySlider = document.querySelector('.contest-gallery__slider');

  if (contestGallerySlider) {
    const swiper = new Swiper(contestGallerySlider, {
      // If we need pagination
      pagination: {
        el: '.contest-gallery__slider .swiper-pagination',
      },

      slidesPerView: 3,
      grid: {
        fill: 'row',
        rows: 2,
      },
      spaceBetween: 40,

      breakpoints: {
        // when window width is >= 0px
        0: {
          slidesPerView: 1,
          spaceBetween: 30
        },
        // when window width is >= 768px
        768: {
          slidesPerView: 2,
          spaceBetween: 30
        },
        // when window width is >= 960px
        960: {
          slidesPerView: 3,
          spaceBetween: 40
        }
      }
    });
  }
}

export { contestGalleryInit }
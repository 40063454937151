function connectionInit() {
  let connectionProviders = document.querySelector('.connection__providers');

  if (connectionProviders) {
    const swiper = new Swiper(connectionProviders, {
      loop: true,
      speed: 2000,
      
      autoplay: {
        delay: 0,
      },

      slidesPerView: 5,
      spaceBetween: 50,
      // Responsive breakpoints
      breakpoints: {
        // when window width is >= 0
        0: {
          slidesPerView: 3,
          spaceBetween: 25
        },
        // when window width is >= 960px
        960: {
          slidesPerView: 4,
          spaceBetween: 30
        },
        // when window width is >= 640px
        1280: {
          slidesPerView: 5,
          spaceBetween: 50
        }
      }
    });
  }
}

export { connectionInit }
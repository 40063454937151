function seePassword() {
  let seePasswordBtn = document.querySelector('.form__input-see-password');

  if (seePasswordBtn) {
    seePasswordBtn.addEventListener('click', (e) => {
      let parent = seePasswordBtn.closest('.form__input-wrapper')
      let input = parent.querySelector('input')
      
      if (input.type === 'text') {
        input.type = 'password'
      } else {
        input.type = 'text'
      }
    })
  }
}

export { seePassword }
function searchInit() {
  let search = document.querySelector('.search');

  if (search) {
    let headerSearchBtn = document.querySelector('.header__search-btn');
    let searchClose = document.querySelector('.search__close');

    headerSearchBtn.addEventListener('click', () => {
      search.classList.add('is-active');
    })

    searchClose.addEventListener('click', () => {
      search.classList.remove('is-active');
    })
  }
}

export { searchInit }